import * as React from "react"
import { SEO } from "../components/seo"
// import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/layout';
// import { StaticImage } from "gatsby-plugin-image"
// import { Link } from "gatsby";

const PressVi = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-5xl text-white font-bold mb-4">Truyền Thông</h1>
        </div>
      </section>
      <section className="pt-14 first-letter:pb-28 px-10 lg:px-36 pt-32">

        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">

          <h2 className="font-heading font-bold text-center text-2xl">Nghị Viên Kim Bernice Nguyễn Tuyên Bố Chiến Dịch Tranh Cử Lịch Sử Cho Chức Dân Biểu Liên Bang, Địa Hạt 45</h2>

          <p className="text-center"><em>Nếu thắng cử, Nghị Viên Kim Bernice Nguyễn, con gái của gia đình tị nạn gốc Việt, sẽ là nữ Dân Biểu gốc Việt đầu tiên của California được bầu vào Quốc Hội Hoa Kỳ, mang theo những kinh nghiệm và giá trị chính trị địa phương và thành tích làm việc với tinh thần hòa hợp hai đảng cho đợt bầu cử chiến trường của Quốc Hội sắp tới.</em></p>

          <div className="text-justify space-y-6">
            <p>GARDEN GROVE, CA -  Hôm nay, cô Kim Bernice Nguyễn, nghị viên thành phố Garden Grove, tuyên bố tranh cử cho chức Dân Biểu Liên Bang, Địa Hạt 45 của California, cũng là địa hạt nơi cô sinh trưởng, theo học trường công cộng địa phương, và là nơi cô phục vụ hai nhiệm kỳ trong Hội Đồng Thành Phố Garden Grove.</p>

            <p>Cô Kim cho biết trong tuyên bố tranh cử rằng: “Washington DC thật hỗn độn. Những thỏa thuận bí mật với thành phần cánh hữu cực đoan cho chức vụ lãnh đạo không phải là cách đất nước chúng ta vận hành. Trong khi Washington DC đang chơi các trò chơi chính trị, thì các gia đình ở California đang phải vật lộn với sự gia tăng giá sinh hoạt, giáo dục, y tế, cũng như tình trạng môi trường bị tàn phá do lũ lụt và cháy rừng. Trong vai trò nghị viên, tôi làm việc với tinh thần hòa hợp hai đảng để tạo ra phát triển kinh tế, các dự án cơ sở hạ tầng, và nhiều công viên giải trí trong các khu dân cư, song song với việc làm giảm tội phạm và tình trạng vô gia cư. Tại Quốc Hội, tôi sẽ là tiếng nói đại diện cho tất cả gia đình ở California trong khi làm việc để giảm chi phí đồng thời giải quyết khủng hoảng khí hậu. Đây là lúc khôi phục lại thanh liêm và trách nhiệm trong khi tạo ra kết quả thật sự.”</p>

            <p>Là con gái của một gia đình tị nạn gốc Việt và một di dân Mexico, nữ Nghị Viên Kim Bernice Nguyễn là người đầu tiên trong gia đình tốt nghiệp trung học và đại học. Khi mới 25 tuổi, cô tạo nên lịch sử khi trở thành dân cử trẻ nhất và là nữ nghị viên gốc Hispanic đầu tiên được bầu vào Hội Đồng Thành Phố Garden Grove. Với vai trò là một nhà lãnh đạo về nữ quyền, cô Kim đứng lên chống lại sự quấy rối và tìm kiếm sự bình đẳng và công lý ở miền Nam California. Cô tranh đấu để bảo đảm rằng những người nhập cư, giống như cha mẹ cô, có phẩm giá và cơ hội để thành công trong cộng đồng của chúng ta. </p>

            <p>Là một nhà lãnh đạo địa phương, cô Kim từng theo học các trường Clinton-Mendenhall Elementary, Doig Intermediate, và Santiago High School. Trước đây, cô Kim từng làm việc cho CalOptima, một tổ chức bảo hiểm y tế của Quận Cam, và cô hiện là giám đốc chương trình của một tổ hợp y tế lớn. Là một chuyên gia về chăm sóc sức khỏe, cô sẽ mang đến kinh nghiệm và kiến thức cần thiết về sức khỏe cộng đồng ở Quốc Hội.</p>

            <p>Địa Hạt 45 của California có nhiều triển vọng cho đảng Dân Chủ năm 2024. Đây cũng là địa hạt +6 của Tổng Thống Joe Biden với cử tri đảng Dân Chủ hơn cử tri đảng Cộng Hòa khoảng 5.6%. Thêm vào đó, cử tri trong Địa Hạt 45 có gốc Châu Á-Thái Bình Dương (AAPI) và gốc Hispanic chiếm đa số là 51.6%, mà trong đó cử tri gốc Việt chiếm đại đa số cử tri gốc AAPI đến 58.4%.</p>

            <p>Nếu đắc cử, cô Kim Bernice Nguyễn sẽ là người gốc Việt đầu tiên của California được bầu vào Quốc Hội và là Dân Biểu Liên Bang gốc Việt thứ ba trong lịch sử Hoa Kỳ. Cô cũng là nữ Dân Biểu gốc Á và Hispanic đầu tiên và là một phần trong thế hệ những người quyết dấn thân để thay đổi các vấn đề nhức nhối của xã hội.</p>
          </div>

        </div>
      </section>
    </Layout>
  )
}

export default PressVi

export const Head = ({location}) => <SEO title="Truyền Thông" pathname={location.pathname} />
